import React, { Component } from 'react';
import { Link } from "gatsby"

class PopularTopics extends Component {

  render() {

    const spotlightBlue = {
      fill: `#0a47ed`,
    };

    const lightBlue = {
      fill: `#AEC1E6`,
    };

    return(
      <div className="poptopic-background">
      <div className="layout-wrapper">
        <div className="popular-topics">
          <h2>Popular UX Topics</h2>
          <div className="poptopic-list">
            <div className="poptopic-item">
              <Link to="/topic/accessibility/">
                <div className="icon-background">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48.1" height="48" viewBox="0 0 48.1 48">
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <g>
                          <path d="M24.05,9a15,15,0,1,0,15,15A15,15,0,0,0,24.05,9Zm0,22a7,7,0,1,1,7-7A7,7,0,0,1,24.05,31Z" style={lightBlue}/>
                          <g>
                            <path d="M48,23.7C45.07,14.46,35.22,8,24.05,8S3,14.46.1,23.7L0,24l.1.3C3,33.54,12.88,40,24.05,40s21-6.46,24-15.7l.1-.3Zm-10,.3a14,14,0,1,1-14-14A14,14,0,0,1,38.05,24ZM2.1,24a20.32,20.32,0,0,1,11-11.6,15.93,15.93,0,0,0,0,23.2A20.32,20.32,0,0,1,2.1,24ZM35,35.6a15.93,15.93,0,0,0,0-23.2A20.32,20.32,0,0,1,46,24,20.32,20.32,0,0,1,35,35.6Z" style={spotlightBlue}/>
                            <path d="M24.05,32a8,8,0,1,0-8-8A8,8,0,0,0,24.05,32Zm0-14a6,6,0,1,1-6,6A6,6,0,0,1,24.05,18Z" style={spotlightBlue}/>
                          </g>
                          <rect x="0.05" width="48" height="48" style={{fill: `none`}}/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="link-text">Accessibility</span>
              </Link>
            </div>

            <div className="poptopic-item">
              <Link to="/topic/design-process/">
                <div className="icon-background">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <g>
                          <path d="M13,27h4l-4-4L9,19,5,23,1,27H5A20.95,20.95,0,0,0,39.92,39.47,17.95,17.95,0,0,1,13,27Z" style={lightBlue}/>
                          <path d="M43,21A20.95,20.95,0,0,0,8.08,8.53,17.95,17.95,0,0,1,35,21H31l4,4,4,4,4-4,4-4Z" style={lightBlue}/>
                          <g>
                            <path d="M25.76,46A22.09,22.09,0,0,1,4.15,28H1a1,1,0,0,1-.92-.62,1,1,0,0,1,.21-1.09l8-8a1,1,0,0,1,1.42,0l8,8a1,1,0,0,1,.21,1.09A1,1,0,0,1,17,28H14.25A16.95,16.95,0,0,0,39.41,38.61a1,1,0,0,1,1.19,1.6A22,22,0,0,1,25.76,46ZM3.41,26H5a1,1,0,0,1,1,.86A20,20,0,0,0,32.14,43c-.46,0-.92,0-1.38,0A18.94,18.94,0,0,1,12,27.17a1.06,1.06,0,0,1,.23-.82A1,1,0,0,1,13,26h1.56L9,20.41Z" style={spotlightBlue}/>
                            <path d="M39,30a1,1,0,0,1-.71-.29l-8-8a1,1,0,0,1-.21-1.09A1,1,0,0,1,31,20h2.75A16.95,16.95,0,0,0,8.59,9.39a1,1,0,0,1-1.32-.27A1,1,0,0,1,7.4,7.79,22,22,0,0,1,43.85,20H47a1,1,0,0,1,.92.62,1,1,0,0,1-.21,1.09l-8,8A1,1,0,0,1,39,30Zm-5.59-8L39,27.59,44.59,22H43a1,1,0,0,1-1-.86A20,20,0,0,0,15.86,5.05c.46,0,.92,0,1.38,0A18.94,18.94,0,0,1,36,20.83a1.06,1.06,0,0,1-.23.82A1,1,0,0,1,35,22Z" style={spotlightBlue}/>
                          </g>
                          <rect width="48" height="48" style={{fill: `none`}}/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="link-text">Design Process</span>
              </Link>
            </div>

            <div className="poptopic-item">
              <Link to="/topic/interaction-design/">
                <div className="icon-background">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <g>
                          <g>
                            <path d="M35,23.5a2.4,2.4,0,0,0-1.5.57V24a2.51,2.51,0,0,0-2.07-2.41,1.76,1.76,0,0,1,.07.33v7.64c0,5.58-4.61,9.94-10.5,9.94a10.72,10.72,0,0,1-2.61-.32l.26.35a10.54,10.54,0,0,0,8.35,4c3.5,0,10.5-6,10.5-13V25.92A2.47,2.47,0,0,0,35,23.5Z" style={lightBlue}/>
                          </g>
                          <g>
                            <path d="M34.5,22.5a2.84,2.84,0,0,0-1.12.23A3,3,0,0,0,30.5,20.5a2.84,2.84,0,0,0-1.12.23A3,3,0,0,0,26.5,18.5a2.82,2.82,0,0,0-1,.19V10.5a3,3,0,0,0-6,0v16a.4.4,0,0,1-.25.39,1.75,1.75,0,0,1-1.63-.13c-1.22-.79-2.51-1.6-3.28-2a3.52,3.52,0,0,0-3.47,0,2.85,2.85,0,0,0-1.31,1.86A2.8,2.8,0,0,0,10,28.87l7.71,10.47A11.07,11.07,0,0,0,26.5,43.5c6.17,0,11-4.58,11-10.44V25.42A3,3,0,0,0,34.5,22.5Zm-8,19a9.06,9.06,0,0,1-7.16-3.37L11.66,27.7a.82.82,0,0,1-.14-.66.84.84,0,0,1,.4-.56,1.52,1.52,0,0,1,1.45,0c.74.41,2,1.19,3.18,2a3.68,3.68,0,0,0,3.65.21,2.4,2.4,0,0,0,1.3-2.15v-16a1,1,0,0,1,2,0v16a1,1,0,0,0,2,0v-5a1,1,0,0,1,2,0v5a1,1,0,0,0,2,0v-3a1,1,0,0,1,2,0v3a1,1,0,0,0,2,0v-1a1,1,0,0,1,1-1,1,1,0,0,1,1,.92v7.64C35.5,37.79,31.55,41.5,26.5,41.5Z" style={spotlightBlue}/>
                            <path d="M17.17,13.44a1,1,0,0,0,.61-1.28,5.16,5.16,0,0,1-.28-1.66,5,5,0,1,1,9.71,1.67,1,1,0,0,0,.62,1.27,1,1,0,0,0,.33.06,1,1,0,0,0,.94-.67,6.84,6.84,0,0,0,.4-2.33,7,7,0,0,0-14,0,6.84,6.84,0,0,0,.4,2.33A1,1,0,0,0,17.17,13.44Z" style={spotlightBlue}/>
                          </g>
                          <rect width="48" height="48" style={{fill: `none`}}/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="link-text">Interaction Design</span>
              </Link>
            </div>

            <div className="poptopic-item">
              <Link to="/topic/usability/">
                <div className="icon-background">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48.39" height="48.39" viewBox="0 0 48.39 48.39">
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <g>
                          <path d="M29,1.15,15,1.27a4,4,0,0,0-4,4l0,4,22-.18,0-4A4,4,0,0,0,29,1.15Z" style={lightBlue}/>
                          <polygon points="32.45 33.13 14.07 33.03 11.28 34.63 11.74 38 13.4 40.78 19.3 40.82 23.98 41.82 20.78 37.8 22.18 35.34 23.93 35.36 27.3 37.09 32.21 37.93 33.58 34.23 32.45 33.13" style={lightBlue}/>
                          <g>
                            <path d="M19.05,6.23l6,0a1,1,0,0,0,1-1,1,1,0,0,0-1-1l-6,0a1,1,0,0,0-1,1A1,1,0,0,0,19.05,6.23Z" style={spotlightBlue}/>
                            <path d="M34.15,18.81,34,5.11a5,5,0,0,0-5-5L15,.27a5,5,0,0,0-5,5l.26,32a5,5,0,0,0,5,5l8-.07a.7.7,0,0,0,.14,0l4.09,5.58a1,1,0,0,0,.82.41A1,1,0,0,0,29,48a1,1,0,0,0,.22-1.4l-6.73-9.16a.86.86,0,0,1-.15-.66.87.87,0,0,1,.4-.56,1.52,1.52,0,0,1,1.45,0c.74.41,2,1.18,3.19,1.93s3.83,1.56,5.56.59a2.77,2.77,0,0,0,1.38-2.59l-.12-15a5,5,0,0,1,2,4l.18,22a1,1,0,0,0,1,1,1,1,0,0,0,1-1l-.18-22A7,7,0,0,0,34.15,18.81ZM12.08,10.29l20-.16.08,10,.1,12-20,.16Zm2.94-8,14-.12a3,3,0,0,1,3,3l0,3-20,.16,0-3A3,3,0,0,1,15,2.27Zm.3,38a3,3,0,0,1-3-3l0-3,10-.08a3.65,3.65,0,0,0-.64.28,2.84,2.84,0,0,0-.81,4.09L22,40.21ZM31.93,37c-.81.45-2.68,0-3.51-.54-1.24-.78-2.53-1.58-3.3-2a4.62,4.62,0,0,0-.62-.23l7.77-.06,0,2C32.3,36.76,32.05,36.89,31.93,37Z" style={spotlightBlue}/>
                          </g>
                          <rect x="0.19" y="0.19" width="48" height="48" transform="translate(-0.2 0.2) rotate(-0.46)" style={{fill: `none`}}/>
                          <rect x="15.18" y="13.11" width="14" height="8" style={lightBlue}/>
                          <path d="M29.18,12.11h-14a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h14a1,1,0,0,0,1-1v-8A1,1,0,0,0,29.18,12.11Zm-1,8h-12v-6h12Z" style={spotlightBlue}/>
                          <path d="M29.18,24.11h-14a1,1,0,0,0,0,2h14a1,1,0,0,0,0-2Z" style={spotlightBlue}/>
                          <path d="M25.18,28.11h-10a1,1,0,0,0,0,2h10a1,1,0,0,0,0-2Z" style={spotlightBlue}/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="link-text">Usability</span>
              </Link>
            </div>

            <div className="poptopic-item">
              <Link to="/topic/user-research/">
                <div className="icon-background">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <g>
                          <circle cx="30.5" cy="8.5" r="6.5" style={lightBlue}/>
                          <path d="M30.79,15a14.13,14.13,0,0,0-7.24,2l-1.36,3.19-3.33,6.1L29,32H45V29.21A14.21,14.21,0,0,0,30.79,15Z" style={lightBlue}/>
                          <polyline points="31 36 37 36 35 42 31 42" style={lightBlue}/>
                          <g>
                            <path d="M47,31H46V29.21A15.23,15.23,0,0,0,34.87,14.57a7.49,7.49,0,1,0-8.57.13A15.6,15.6,0,0,0,23,16.13h0a8,8,0,1,0-11.76,9.58A16,16,0,0,0,3.54,31H1a1,1,0,0,0,0,2H2.17A16,16,0,0,0,0,41v5H0a1,1,0,0,0,1,1H31a1,1,0,0,0,1-1V43h3a1,1,0,0,0,1-.68l2-6a1,1,0,0,0-.14-.9A1,1,0,0,0,37,35H31a.76.76,0,0,0-.16,0,16.62,16.62,0,0,0-1-2H47a1,1,0,0,0,0-2Zm-3-1.79V31H32V28.41l1.71-1.7a1,1,0,0,0,.28-.83L33,18.1l.6-1.79A13.23,13.23,0,0,1,44,29.21ZM32,25.64l-.95.95-.95-.95L30.88,19h.24ZM30.79,16c.28,0,.54,0,.81,0l-.32,1h-.56l-.32-1C30.53,16,30.66,16,30.79,16ZM25,8.5A5.5,5.5,0,1,1,30.5,14,5.51,5.51,0,0,1,25,8.5ZM9.57,19a6,6,0,1,1,6,6A6,6,0,0,1,9.57,19Zm26,18-1.33,4H32a16,16,0,0,0-.52-4ZM30,45H2V41a14,14,0,1,1,28,0ZM20.13,25.56A8,8,0,0,0,23.57,19a9.15,9.15,0,0,0-.1-1,1,1,0,0,0,.59-.12,13.2,13.2,0,0,1,4.3-1.59L29,18.1l-1,7.78a1,1,0,0,0,.28.83L30,28.41V31H28.47A16,16,0,0,0,20.13,25.56Z" style={spotlightBlue}/>
                            <path d="M41.32,35.05a1,1,0,0,0-1.27.63l-2,6A1,1,0,0,0,38.69,43,1.12,1.12,0,0,0,39,43a1,1,0,0,0,1-.68l2-6A1,1,0,0,0,41.32,35.05Z" style={spotlightBlue}/>
                          </g>
                          <rect width="48" height="48" style={{fill: `none`}}/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="link-text">User Research</span>
              </Link>
            </div>

            <div className="poptopic-item">
              <Link to="/topic/ux-strategy/">
                <div className="icon-background">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <g>
                        <path d="M15,2.13V43l15.68,3.49v-2l1.63-3.87,2.29-1.62-.16-1.51-1.12-3.62,2.5-2.51,3.32.49,1.54,3-1,2.53L39,38.91l3,2.3,1.31,2.25,1.37-.06L47,38.21l-.9-5.36L43.32,29,40,27.46l-5.18-.22L33,26.67,32.56,5.88S24.15,2.63,24,2.63s-9.52-1-9.52-1Z" style={lightBlue}/>
                        <circle cx="8" cy="14" r="3" style={lightBlue}/>
                        <g>
                          <path d="M47.6.2A1,1,0,0,0,46.72,0L33,4,15.22,0H15a.93.93,0,0,0-.29,0h0L.72,4A1,1,0,0,0,0,5V47a1,1,0,0,0,.4.8A1,1,0,0,0,1,48a1,1,0,0,0,.28,0L15,44,32.78,48,33,48h4A11,11,0,0,0,48,37V1A1,1,0,0,0,47.6.2ZM2,5.75,14,2.33V28.07a1,1,0,0,0-.42-.24,4.65,4.65,0,0,1-1.49-.66A1,1,0,1,0,11,28.82a6.5,6.5,0,0,0,2.13.95l.25,0a1,1,0,0,0,.66-.26V42.25L2,45.67Zm14-3.5L32,5.8v8.46a1,1,0,0,0-.61,0,7.49,7.49,0,0,0-2.09.95,1,1,0,0,0-.3,1.39,1,1,0,0,0,.84.45,1,1,0,0,0,.55-.16,5.43,5.43,0,0,1,1.52-.7l.09,0V27a1.42,1.42,0,0,0,0,.2,11,11,0,0,0-2.29,18.05L16,42.2ZM34.32,34.89A2.68,2.68,0,1,1,37,37.58,2.69,2.69,0,0,1,34.32,34.89ZM37,46a8.92,8.92,0,0,1-5.25-1.72,5.28,5.28,0,0,1,10.5,0A8.92,8.92,0,0,1,37,46Zm6.93-3.33a7.27,7.27,0,0,0-3.77-4.34,4.68,4.68,0,1,0-6.32,0,7.27,7.27,0,0,0-3.77,4.34A8.92,8.92,0,0,1,28,37a9,9,0,0,1,18,0A8.92,8.92,0,0,1,43.93,42.67ZM41.8,27.12a1,1,0,0,0,.8-.69A11.71,11.71,0,0,0,43,24.29a1,1,0,0,0-.92-1.08,1,1,0,0,0-1.07.92,10.67,10.67,0,0,1-.31,1.79,1,1,0,0,0,.1.76A11,11,0,0,0,37,26a10.66,10.66,0,0,0-3,.43V5.75L46,2.33V30.7A11,11,0,0,0,41.8,27.12Z" style={spotlightBlue}/>
                          <path d="M18.48,26.87a7.38,7.38,0,0,1-1.55.8,1,1,0,0,0,.33,1.94,1,1,0,0,0,.34-.06,9.33,9.33,0,0,0,2-1,1,1,0,0,0-1.11-1.67Z" style={spotlightBlue}/>
                          <path d="M9.46,22.73A1,1,0,0,0,8.26,22a1,1,0,0,0-.74,1.21,13.32,13.32,0,0,0,.68,2.05,1,1,0,0,0,.92.61,1.09,1.09,0,0,0,.39-.08A1,1,0,0,0,10,24.47,11.83,11.83,0,0,1,9.46,22.73Z" style={spotlightBlue}/>
                          <path d="M8,20a1,1,0,0,0,1-1c0-.26,0-.5,0-.72s0-.26,0-.38a4,4,0,1,0-2,0c0,.12,0,.25,0,.4s0,.5,0,.78a1,1,0,0,0,1,1ZM6,14a2,2,0,1,1,2,2A2,2,0,0,1,6,14Z" style={spotlightBlue}/>
                          <path d="M22.46,22.86c-.4.51-.81,1-1.23,1.5a1,1,0,0,0,.09,1.41A1,1,0,0,0,22,26a1,1,0,0,0,.75-.34c.45-.51.89-1.05,1.31-1.59a1,1,0,1,0-1.58-1.23Z" style={spotlightBlue}/>
                          <path d="M37.92,15.07a10.91,10.91,0,0,0-2.08-.74,1,1,0,1,0-.47,1.94,8.93,8.93,0,0,1,1.67.6,1,1,0,0,0,.44.1,1,1,0,0,0,.9-.56A1,1,0,0,0,37.92,15.07Z" style={spotlightBlue}/>
                          <path d="M41.33,17.94A1,1,0,1,0,39.7,19.1a6.87,6.87,0,0,1,.82,1.54,1,1,0,0,0,.93.64.92.92,0,0,0,.36-.07,1,1,0,0,0,.57-1.29A8.72,8.72,0,0,0,41.33,17.94Z" style={spotlightBlue}/>
                          <path d="M27.69,19.37a1,1,0,0,0-1.53-1.3c-.43.51-.86,1.05-1.28,1.6a1,1,0,0,0,.18,1.4,1,1,0,0,0,1.4-.18C26.87,20.36,27.27,19.85,27.69,19.37Z" style={spotlightBlue}/>
                        </g>
                        <rect width="48" height="48" style={{fill: `none`}}/>
                      </g>
                    </g>
                  </g>
                </svg>
                </div>
                <span className="link-text">UX Strategy</span>
              </Link>
            </div>

            <div className="poptopic-item">
              <Link to="/topic/visual-design/">
                <div className="icon-background">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <g>
                          <g>
                            <rect x="8" y="17" width="12" height="8" style={lightBlue}/>
                            <path d="M46,11l-1-1L44,9V5a2,2,0,0,0-2-2H4A2,2,0,0,0,2,5v6H41.16c-1.77,1.32-4.27,3.31-7.22,6H26.2a.2.2,0,0,0-.2.2V24l6.73-5.89Q31.4,19.36,30,20.78c-1,1-3,3.22-3,3.22l.06.06a4,4,0,0,0-2.89,1.16,4,4,0,0,0-1.11,2.23s0,0,0,0c-.39,1.29.57,2.83-1.69,5.1,0,0,5.09,1.69,8.48-1.7A4,4,0,0,0,31,28h0s2-2,3-3l0,0h4V21l-2.47,2.47A98.29,98.29,0,0,0,46,11Z" style={lightBlue}/>
                          </g>
                          <g>
                            <path d="M21,25V17a1,1,0,0,0-1-1H8a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H20A1,1,0,0,0,21,25Zm-2-1H9V18H19Z" style={spotlightBlue}/>
                            <path d="M18,30H8a1,1,0,0,0,0,2H18a1,1,0,0,0,0-2Z" style={spotlightBlue}/>
                            <path d="M18,34H8a1,1,0,0,0,0,2H18a1,1,0,0,0,0-2Z" style={spotlightBlue}/>
                            <path d="M16,38H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z" style={spotlightBlue}/>
                            <path d="M38,30H34a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Z" style={spotlightBlue}/>
                            <path d="M38,34H30a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z" style={spotlightBlue}/>
                            <path d="M34,38H26a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z" style={spotlightBlue}/>
                            <circle cx="6" cy="7" r="1" style={spotlightBlue}/>
                            <circle cx="10" cy="7" r="1" style={spotlightBlue}/>
                            <circle cx="14" cy="7" r="1" style={spotlightBlue}/>
                            <path d="M46.71,10.29,45,8.59V5a3,3,0,0,0-3-3H4A3,3,0,0,0,1,5V43a3,3,0,0,0,3,3H42a3,3,0,0,0,3-3V14.08c1.16-1.52,1.77-2.43,1.83-2.53A1,1,0,0,0,46.71,10.29Zm-13.34,14-.13.13L31.57,26c-.06-.13-.14-.25-.21-.38a4,4,0,0,0-.22-.4,6.06,6.06,0,0,0-.6-.74,4.83,4.83,0,0,0-1.67-1.09c.61-.67,1.33-1.45,1.81-1.93a95.41,95.41,0,0,1,13.19-11.2l.83.83A110.76,110.76,0,0,1,33.37,24.24ZM37,23.38V24h-.59Zm-7,4.67a3,3,0,0,1-.88,2.12,7.09,7.09,0,0,1-6,1.75,5.45,5.45,0,0,0,.78-3.23,4.06,4.06,0,0,1,.06-.86,1,1,0,0,0,.06-.23,3,3,0,0,1,.83-1.67,3.08,3.08,0,0,1,4.24,0A3,3,0,0,1,30,28h0ZM27,22.5V18h4.4c-.7.66-1.4,1.34-2.13,2.07S27.67,21.76,27,22.5ZM3,5A1,1,0,0,1,4,4H42a1,1,0,0,1,1,1V8.46c-.44.29-1.18.8-2.18,1.54H3ZM43,43a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V12H38.23c-1.36,1.09-2.94,2.42-4.69,4H26.2A1.2,1.2,0,0,0,25,17.2v6.31a4.66,4.66,0,0,0-1.53,1,5,5,0,0,0-1.37,2.66v0a5,5,0,0,0-.16,1.51,3.7,3.7,0,0,1-1.29,3.17A1,1,0,0,0,21,33.52a11.18,11.18,0,0,0,3.3.48,8.58,8.58,0,0,0,6.21-2.41A5,5,0,0,0,32,28.46L34.44,26H38a1,1,0,0,0,1-1V21.23c1.57-1.72,2.9-3.28,4-4.62Z" style={spotlightBlue}/>
                          </g>
                          <rect width="48" height="48" style={{fill: `none`}}/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="link-text">Visual Design</span>
              </Link>
            </div>

            <div className="poptopic-item">
              <Link to="/topic/web-writing/">
                <div className="icon-background">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <g>
                          <rect width="48" height="48" style={{fill: `none`}}/>
                          <g>
                            <polygon points="16.82 26.17 16.82 31.17 21.82 31.17 41.82 11.17 36.82 6.17 16.82 26.17" style={lightBlue}/>
                            <path d="M44.12,5.47l-1.6-1.59a2.39,2.39,0,0,0-3.39,0l-2.3,2.3,5,5,2.3-2.3A2.4,2.4,0,0,0,44.12,5.47Z" style={lightBlue}/>
                          </g>
                          <g>
                            <path d="M16.33,25.67v5a1,1,0,0,0,1,1h5a1,1,0,0,0,.7-.29l20-20,0,0L45,9.4a3.39,3.39,0,0,0,0-4.81L43.4,3a3.39,3.39,0,0,0-4.8,0l-2,2h0l-20,20A1,1,0,0,0,16.33,25.67Zm2,1.42,2.58,2.58H18.33Zm16.5-17.5,1.08,1.08L20.33,26.26l-1.09-1.09Zm-12,19.17-1.09-1.09L37.33,12.09l1.08,1.08Zm17-17L36.24,8.17l1.09-1.08,3.58,3.58ZM42,4.41,43.59,6A1.43,1.43,0,0,1,44,7a1.39,1.39,0,0,1-.41,1L42.32,9.25,38.74,5.68,40,4.41A1.4,1.4,0,0,1,42,4.41Z" style={spotlightBlue}/>
                            <path d="M45,22a1,1,0,0,0-1,1V39a5,5,0,0,1-5,5H9a5,5,0,0,1-5-5V9A5,5,0,0,1,9,4H25a1,1,0,0,0,0-2H9A7,7,0,0,0,2,9V39a7,7,0,0,0,7,7H39a7,7,0,0,0,7-7V23A1,1,0,0,0,45,22Z" style={spotlightBlue}/>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="link-text">Web Writing</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      </div>
    )

  }
}

export default PopularTopics
