import React from "react"
import { graphql } from 'gatsby'
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
import PopularTopics from "../components/populartopics"

const IndexPage = ({ data }) => {

const cardItems = data.allContentfulArticle.edges

  return (
    <Layout
      pageHeading="Stay Current on All Things UX"
      subHeading="Your one-stop resource to learn about the latest in user experience design."
    >
      <SEO title="SpotlightUX" body="home" />
      <main>
        <div className="card-background">
          <div className="layout-wrapper">
            <div className="my-container">
              <h2>Latest Articles</h2>
              <div className="card-list">
                {cardItems.map(({ node }) =>
                    <Card
                      key={node.id}
                      date={node.date}
                      description={node.description.description}
                      source={node.source}
                      title={node.title}
                      articleLink={node.link}
                      author={node.author}
                    />
                )}
              </div>
            </div>
            <div className="articles-cta">
            <Link to="/articles/" className="button" rel="next">Read All Articles
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.828" viewBox="0 0 16 16.828">
              <g id="arrow-right" transform="translate(1 1.414)">
                <line id="Line_1" data-name="Line 1" x2="14" transform="translate(0 7)" fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                <path id="Path_13" data-name="Path 13" d="M12,5l7,7-7,7" transform="translate(-5 -5)" fill="none" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
              </g>
            </svg>
            </Link>
            </div>
          </div>
        </div>
        <PopularTopics />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
query CardQuery {
  allContentfulArticle(sort: {order: DESC, fields: date}, limit: 6) {
    edges {
      node {
        author
        source
        link
        id
        date(formatString: "MMMM D, YYYY")
        description {
          description
        }
        title
      }
    }
  }
}
`

export default IndexPage
