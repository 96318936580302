import React from "react"

const Card = ({ date, description, source, title, articleLink, author, id }) => {
  return (
    <div className="card-wrapper">
      <a target="_blank" rel="noopener noreferrer" href={articleLink} className="card card-link" key={id}>
        <article>
          <div className="card-body">
            <p className="card-text text-style source">{source}</p>
            <h3 className="card-title">{title}</h3>
            <p className="card-text">{description}</p>
            <span className="card-text text-style">{author} | {date}</span>
          </div>
        </article>
      </a>
    </div>
  )
}

export default Card
